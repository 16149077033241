// 
// Modal
// 

/* Custom modifier class for bottom drawer */
@include media-breakpoint-down(md) {
  .modal-drawer {
    --#{$prefix}modal-border-radius: #{$modal-content-border-radius} #{$modal-content-border-radius} 0 0;

    transition: transform $transition-base-duration;
    transform: translateY(100%);
    display: flex;
    align-items: end;
    justify-content: center;
    margin: 0;

    &.show {
      transform: translateY(0);
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      align-items: end;
    }
  }
}