:root,
[data-bs-theme="light"] {

  @each $color, $value in $all-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $all-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }
  
  // Accent
  --#{$prefix}accent-bg: #{$accent-bg};
  --#{$prefix}accent-bg-rgb: #{to-rgb($accent-bg)};

  --#{$prefix}accent-color: #{$accent-color};
  --#{$prefix}accent-color-rgb: #{to-rgb($accent-color)};

  // Fonts
  --#{$prefix}font-display: #{inspect($font-display-bundle)};
  --#{$prefix}font-serif: #{inspect($font-serif-bundle)};
  --#{$prefix}font-monospace: #{inspect($font-monospace-bundle)};

  // Shadows
  --#{$prefix}box-shadow: #{$box-shadow};
  --#{$prefix}box-shadow-1: #{$box-shadow-1};
  --#{$prefix}box-shadow-2: #{$box-shadow-2};
  --#{$prefix}box-shadow-3: #{$box-shadow-3};
  --#{$prefix}box-shadow-4: #{$box-shadow-4};
  --#{$prefix}box-shadow-5: #{$box-shadow-5};
  --#{$prefix}box-shadow-6: #{$box-shadow-6};

  --#{$prefix}box-soft-shadow-1: #{$box-soft-shadow-1};
  --#{$prefix}box-soft-shadow-2: #{$box-soft-shadow-2};
  --#{$prefix}box-soft-shadow-3: #{$box-soft-shadow-3};
  --#{$prefix}box-soft-shadow-4: #{$box-soft-shadow-4};
  --#{$prefix}box-soft-shadow-5: #{$box-soft-shadow-5};
  --#{$prefix}box-soft-shadow-6: #{$box-soft-shadow-6};

  // Logo
  .img-light {
    display: none;
  }

  .img-dark {
    display: block;
  }
}

.navbar-dark,
[data-bs-theme="dark"] .navbar {
  .img-light {
    display: block;
  }
  .img-dark {
    display: none;
  }
}


@if $enable-dark-mode {
  @include color-mode(dark, true) {
    // Logo
    .img-light {
      display: block;
    }
    .img-dark {
      display: none;
    }

    // Accent
    --#{$prefix}accent-bg: #{$accent-bg-dark};
    --#{$prefix}accent-bg-rgb: #{to-rgb($accent-bg-dark)};
    --#{$prefix}accent-color: #{$accent-color-dark};
    --#{$prefix}accent-color-rgb: #{to-rgb($accent-color-dark)};

    // Buttons
    .btn-neutral {
      --#{$prefix}btn-bg: #{$btn-neutral-bg-dark};
      --#{$prefix}btn-border-color: #{$btn-neutral-border-color-dark};
      --#{$prefix}btn-color: #{$btn-neutral-color-dark};
      --#{$prefix}btn-hover-bg: #{$btn-neutral-hover-bg-dark};
      --#{$prefix}btn-hover-color: #{$btn-neutral-hover-color-dark};
      --#{$prefix}btn-hover-border-color: #{$btn-neutral-hover-border-color-dark};
      --#{$prefix}btn-active-bg: #{$btn-neutral-active-bg-dark};
      --#{$prefix}btn-active-color: #{$btn-neutral-active-color-dark};
      --#{$prefix}btn-active-border-color: #{$btn-neutral-active-border-color-dark};
    }
  }
}
