// 
// Progress
// 

// Progress circle

@property --#{$prefix}progress-circle-percentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

.progress-circle {
  --#{$prefix}progress-circle-size: #{$progress-circle-size};
  --#{$prefix}progress-circle-bg: #{$progress-circle-bg};
  --#{$prefix}progress-circle-color: #{$progress-circle-color};
  --#{$prefix}progress-circle-bar-bg: #{$progress-circle-bar-bg};
  --#{$prefix}progress-circle-percentage: var(--value);

  width: var(--#{$prefix}progress-circle-size);
  height: var(--#{$prefix}progress-circle-size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(closest-side, currentColor 80%, transparent 0 99.9%, currentColor 0),
    conic-gradient(var(--#{$prefix}progress-circle-bar-bg) calc(var(--#{$prefix}progress-circle-percentage) * 1%), var(--#{$prefix}progress-circle-bg) 0);
  font-size: calc(var(--#{$prefix}progress-circle-size) / 5);
}

.progress-circle-animate {
  animation: progressCircleGrow 3s 1 forwards;
}

.progress-circle-counter {
  &::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
    color: var(--#{$prefix}progress-circle-color);
  }
}

.progress-circle-xl {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-xl};
}

.progress-circle-lg {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-lg};
}

.progress-circle-sm {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-sm};
}

@each $color, $value in $theme-colors {
  .progress-circle-#{$color} {
    --#{$prefix}progress-circle-color: var(--#{$prefix}#{$color});
    --#{$prefix}progress-circle-bar-bg: var(--#{$prefix}#{$color});
  }
}

// Keyframes

@keyframes progressCircleGrow {
  0%, 33% {
    --#{$prefix}progress-circle-percentage: 0;
  }
  100% {
    --#{$prefix}progress-circle-percentage: var(--value);
  }
}