// Color system

// White/Grays/Black colors
$white:    #FFF !default;
$gray-50:  #fafafa !default;
$gray-100: #f5f5f5 !default;
$gray-200: #e5e5e5 !default;
$gray-300: #d4d4d4 !default;
$gray-400: #a1a1a1 !default;
$gray-500: #737373 !default;
$gray-600: #525252 !default;
$gray-700: #404040 !default;
$gray-800: #262626 !default;
$gray-900: #171717 !default;
$gray-950: #0a0a0a !default;
$black:    #000 !default;

$grays: (
	"50": $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "950": $gray-950
) !default;

// Base colors
$cyan:      #7fc2ff !default;
$sky-blue:  #0690f1 !default; 
$blue:      #335ccf !default;
$indigo:    #6366f1 !default;
$purple:    #7d52f4 !default;
$pink:      #fb4ba3 !default;
$red:       #fb3748 !default;
$orange:    #ff9147 !default;
$yellow:    #f6b51e !default;
$green:     #1fc16b !default;
$lime:      #c8ff6a !default;
$teal:      #22d3bb !default;

$colors: (
  "sky-blue":   $sky-blue,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "lime":       $lime,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;

$lime-100: tint-color($lime, 80%) !default;
$lime-200: tint-color($lime, 60%) !default;
$lime-300: tint-color($lime, 40%) !default;
$lime-400: tint-color($lime, 20%) !default;
$lime-500: $lime !default;
$lime-600: shade-color($lime, 20%) !default;
$lime-700: shade-color($lime, 40%) !default;
$lime-800: shade-color($lime, 60%) !default;
$lime-900: shade-color($lime, 80%) !default;

$limes: (
  "lime-100": $lime-100, 
  "lime-200": $lime-200,
  "lime-300": $lime-300,
  "lime-400": $lime-400,
  "lime-500": $lime-500,
  "lime-600": $lime-600,
  "lime-700": $lime-700,
  "lime-800": $lime-800,
  "lime-900": $lime-900
) !default;

$sky-blue-100: tint-color($sky-blue, 80%) !default;
$sky-blue-200: tint-color($sky-blue, 60%) !default;
$sky-blue-300: tint-color($sky-blue, 40%) !default;
$sky-blue-400: tint-color($sky-blue, 20%) !default;
$sky-blue-500: $sky-blue !default;
$sky-blue-600: shade-color($sky-blue, 20%) !default;
$sky-blue-700: shade-color($sky-blue, 40%) !default;
$sky-blue-800: shade-color($sky-blue, 60%) !default;
$sky-blue-900: shade-color($sky-blue, 80%) !default;

$sky-blues: (
  "sky-blue-100": $sky-blue-100, 
  "sky-blue-200": $sky-blue-200,
  "sky-blue-300": $sky-blue-300,
  "sky-blue-400": $sky-blue-400,
  "sky-blue-500": $sky-blue-500,
  "sky-blue-600": $sky-blue-600,
  "sky-blue-700": $sky-blue-700,
  "sky-blue-800": $sky-blue-800,
  "sky-blue-900": $sky-blue-900
) !default;

// Theme colors
$primary:       $sky-blue-400 !default;
$secondary:     $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;
